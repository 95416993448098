import React, {useState, useEffect} from "react";
import {EmptyBox, Flex, GrayBox, Title2} from "../../common/DeskComponent";
import {Button, message, Space, Upload} from "antd";
import {CaretRightOutlined, InboxOutlined} from "@ant-design/icons";
import {isFileListArrayDifferent} from "../../common/Util";



//증거보관함 관련.
import "./draggable.css";
import EvidenceUploader from "../../components/EvidenceUploader";
import axios from "axios";
import useAxios from "../../hooks/useAxios";
const { Dragger } = Upload;
const draggerProps = {
    name: 'file',
    multiple: true,
    listType: "picture",
    //action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',

    //멀티용 함수2개 추가 2409


};

const EvidenceFolder = ({cmId, cmFieldKv, setCmFieldKv, paramEvidenceFolderFileList, paramEvidenceHide}) => {

    const [evidenceFolderFileList, setEvidenceFolderFileList] = useState(paramEvidenceFolderFileList?paramEvidenceFolderFileList:[]); //[ {uid,name}, {uid,name} ]형식.
    const [evidenceHide, setEvidenceHide] = useState(paramEvidenceHide?paramEvidenceHide:false);

    const {axiosGet, axiosPost} = useAxios();
    const [messageApi, contextHolder] = message.useMessage();


    //=beforeUpload Dragger
    const handleEvidenceS3Upload = async(file) => {

        console.log('evi handleEvidenceS3Upload,', file);
        let storingFile = {name:file.name, uid:file.uid, thumbUrl:file.thumbUrl};

        let lastIndex = file.name.lastIndexOf('.');
        let fileExtension = (lastIndex > 0)? file.name.substring(lastIndex) : '.file' ;
        console.log('fileExtension:', fileExtension);

        //returnURL(imageUrl) 을 미리 받아야 함.
        const {presignedUrl, returnUrl}  = await axiosGet(`/api/file/publicFile/generatePresignedUrl/${fileExtension}`);
        console.log('evi presignedUrl', presignedUrl);

        try {
            const ret = await axios.put(presignedUrl, file, {
                headers: {
                    'Content-Type': file.type,
                },
            })
            console.log('retData:', ret);
            if(ret.status == 200) {
                storingFile.url = returnUrl;
                console.log('evi retUrl:', storingFile);

                // setEvidenceFolderFileList([...evidenceFolderFileList, storingFile]);
                setEvidenceFolderFileList(prevState => [...prevState, storingFile]);

            }
        }catch(e) {
            console.log(e);
        }

    }

    //=onChange Dragger
    const onChangeEvidenceFolder =(info) => {
        const { status } = info.file;
        console.log('evi onChange:', info); //info.fileList 까지는 multiFile이 들어옴. 그런데 file에는 1개만 들어옴.

        if (status === 'removed') { //removed시에는 uploading->removed 로 호출됨. uid기준으로 삭제하자.
            console.log('evi dragger oneFile removed:', info.file, info.fileList);  //삭제시
            setEvidenceFolderFileList(prev => prev.filter((f) => f.uid !== info.file.uid));

            return;
            //fileList설정후 부터 이상하게 done으로 안들어옴.
        }

        if (status !== 'uploading') {
            console.log('evi dragger oneFile uploading:', info.file, info.fileList);  //drop한 파일들 여기에 출력됨. file.uid


        }
        if (status === 'done') { //add시에는 uploading->done 으로 호출됨.
            console.log('evi dragger oneFile done:', info.file, info.fileList);  //drop한 파일들 여기에 출력됨. file.uid
            //handleEvidenceS3Upload 에서 수행중.  setEvidenceFolderFileList([...evidenceFolderFileList, info.file]);
            message.success(`${info.file.name} 증거보관함에 추가되었습니다..`); //info.filelist = [ {uid, name} ]

        }
        else if (status === 'error') {
            //message.error(`${info.file.name} 증거보관함에 추가 오류. 사이즈가 너무크면 줄여주세요.`);
            console.log('evi error: 그래도 s3에는 문제없는듯', info.file );
        }

        console.log('evi evidenceFolderFileList', evidenceFolderFileList);
    };

    const saveEvidenceFolder = async() => {
        setCmFieldKv(prev => ({...prev, ['원장.채권자.증거보관함']:evidenceFolderFileList}) )

        let retInt = await axiosPost(`/api/ipoUser/askFlow/cmFieldKv/${cmId}`, {'원장.채권자.증거보관함':evidenceFolderFileList});
        if (retInt > 0) {
            messageApi.info('증거보관함이 저장되었습니다.');
        }

        // setParentEvidenceFolderFileList(evidenceFolderFileList);

    }
    return(
        <GrayBox>
            {contextHolder}
            <Flex> <Space>
                <Title2 style={{marginTop:-30, marginBottom:-20}}> 증거보관함 </Title2>
                <CaretRightOutlined onClick={()=>setEvidenceHide(prev=>!prev)} rotate={evidenceHide ? 0 : 90} />
            </Space></Flex>

            <div style={{marginTop:10, display: evidenceHide ? 'none' : 'block'}}>
                <Dragger {...draggerProps}  className="dragger-parent"
                         style={{backgroundImage:'url("/evidenceFolder.png")', backgroundSize: 'cover', width:688, height:102, opacity:'0.8', }}
                         onChange={onChangeEvidenceFolder}

                         beforeUpload={handleEvidenceS3Upload}
                         fileList={evidenceFolderFileList}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined/>
                    </p>
                    <p className="ant-upload-text"> 여기를 클릭하거나 파일을 드래그해서 증거를 추가하세요.</p>

                </Dragger>

                {isFileListArrayDifferent(cmFieldKv['원장.채권자.증거보관함'], evidenceFolderFileList) &&
                    <EmptyBox>
                        <Button style={{marginTop:0, marginBottom:-10, backgroundColor: '#ffd118', color: 'black'}} type={'primary'}
                                onClick={()=>saveEvidenceFolder()}> 증거보관함 저장 </Button>
                    </EmptyBox>
                }


            </div>

        </GrayBox>


    )
}

export default EvidenceFolder;