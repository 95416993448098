import React, {useState, useEffect, useMemo, useCallback} from "react";
import  {Upload, Input, Button, Spin, Space, Image, Modal, Checkbox} from "antd";
import {DownloadOutlined, FolderViewOutlined, UploadOutlined} from '@ant-design/icons';
import useAxios from "../hooks/useAxios";
import axios from "axios";
import {EmptyBox, Flex, FlexColumn, WhiteButton,FlexColumnCenter} from "../common/DeskComponent";
import useModal from "../hooks/useModal";

const zipImageUrl = 'https://v1-prod-s3-userprofile.s3.ap-northeast-2.amazonaws.com/images/24011706075032586bU3aRddNInrBEPDgyn.png';
const pdfImageUrl = 'https://v1-prod-s3-userprofile.s3.ap-northeast-2.amazonaws.com/images/24011706244143075FiNf626cYM4AQ2H.png';
const xlsImageUrl = 'https://v1-prod-s3-userprofile.s3.ap-northeast-2.amazonaws.com/images/24011706244119108aWg89gXKMaKc4b0bza.png';

/**
 * readOnly과 downloadOnly 는 evidenceFileList 필요없음. - fileList만이용.
 * readOnly: b2c history에서 사용
 * downloadOnly = readOnly + download 버튼 boAdmin에서 사용 중.
 */


    //Modal에서 download가 잘안되는 현상: onOk를 props로 전달하기 위해 Modal을 컴포넌트로 분리.
const ModalComponent = ({cmId, onOk, upFileList, setUpFileList, modalOpen, downloadOnly, readOnly, toggle, paramTargetList, onSelectDone}) => {

        const {axiosGet, axiosPost} = useAxios();
        const [targetList, setTargetList] = useState(paramTargetList?paramTargetList:[]);

        console.log('evi ModalComponent:', modalOpen);

        useEffect(()=>{
            console.log('===evidenceFolderList: USE EFFECT');

            //targetList= evidenceFileList refresh : upload용 일때.
            if (!readOnly && !downloadOnly ) {
                firstSearch();
            }

        }, [])

        const firstSearch = async()=> {

            let folderList = await axiosGet(`/api/ipoUser/creditMaster/cmFieldValue/${cmId}/원장.채권자.증거보관함`)
            console.log('evidenceFolderList:', folderList);

            if (Array.isArray(folderList)) {
                setTargetList(folderList);
            }
        }


        const isListContainsFile = (list, uid)=> {
            if (Array.isArray(list) && list.filter( (file)=> file.uid === uid).length > 0) {
                return true;
            }
            return false;
        }
        const addDelUpList = (checked, file) => {
            //add
            if (checked) {
                //upFileList.push(file);
                setUpFileList(prev => [...prev, file]);
                //remove
            }else {
                setUpFileList( prev => prev.filter((f) => f.uid !== file.uid) );
            }
        }

        return(
            <Modal
                // title={ '[ ' + subAskModalData.title + ' 추가 질문 ]'}
                title={ <div style={{ fontSize: '18px' }}> {(!downloadOnly && !readOnly)?'증거보관함에서 증거 선택' : '증거 목록'} </div>}
                visible={modalOpen}
                onCancel={toggle}
                footer={null}
                destroyOnClose={true}
                getContainer={false}
                bodyStyle={{padding:0}}
                width={500}
                maskClosable={true}
                // onOk = {downloadImage} : props로 받기위해 ModalComponent로 분리함.
            >
                <FlexColumn><Space direction={'vertical'}>

                    { !readOnly && !downloadOnly && targetList.length===0 &&

                       <div style={{margin:16}}> 증거보관함에 자료를 먼저 추가하세요. </div>
                    }

                    {targetList.map((file,idx) =>
                        <Flex style={{backgroundColor:'#f0f0f0'}}>
                            <Image style={{width:45, height: 60}} src={file.url}/>

                            <div style={{width:downloadOnly?300:(readOnly?360:340), margin:20}}>
                                {file.name}
                            </div>
                            {downloadOnly &&
                                <Button style={{margin:20}} icon={<DownloadOutlined/>} onClick={() => onOk(file.url, file.name)}></Button>
                            }
                            { !readOnly && !downloadOnly &&
                                <Checkbox  onChange={(e) => addDelUpList(e.target.checked, file)} checked={isListContainsFile(upFileList, file.uid)}  style={{marginTop:20}}> </Checkbox>
                            }
                        </Flex>
                    )}

                </Space> </FlexColumn>

                {(!downloadOnly && !readOnly) && targetList.length > 0 &&
                    <FlexColumnCenter>
                        <Button onClick={onSelectDone} style={{marginTop:16, backgroundColor: '#ffd118', color:'black'}} type={'primary'}> 선택완료 </Button>
                    </FlexColumnCenter>
                }
            </Modal>
        )
    }



//cmId는 folder refresh용도:upload에서만 필요함. :qId는 디버깅용.
const EvidenceUploader = ({cmId, 필수, onChange, fileList,  readOnly, downloadOnly, evidenceFileList=[]}) => {

    // console.log('evidenceUploader: cmId=, qId, 증거필수여부', cmId, 필수);

    // const [loading, setLoading] = useState(false);

    const [modalOpen, setModalOpen, selected, setSelected, setModalState, toggle] = useModal();

    //upload할 fileList관리.
    const [upFileList, setUpFileList] = useState(Array.isArray(fileList)?fileList:[]);
    //console.log('EvidenceUploader upFileList:', upFileList);

    //list loop용 임시변수
    const [targetList, setTargetList] = useState((readOnly || downloadOnly)? upFileList : evidenceFileList);

    //생성후 들어오는 경우가 있어 필요.
    useEffect(() => {
        setUpFileList(Array.isArray(fileList)?fileList:[]);
        if (readOnly || downloadOnly) {
            setTargetList(Array.isArray(fileList)?fileList:[]); //출력용 임시변수
        }
    }, [fileList]);


    //modal에선 잘 안되므로 파라미터로 전달 필요.
    const downloadImage = async (url, filename) => {
        console.log('download click: ', filename, url);
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            const urlObject = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = urlObject;
            a.download = filename || 'download';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(urlObject); // 생성된 URL 객체 해제
        } catch (error) {
            console.error('Download Error:', error);
        }

    }



    //StoryTelling의 onChange를 호출:CmFieldKv만 세팅. 실제저장은  StoryTelling.js에서 수행.
    const onSelectDone = ()=> {

        onChange(upFileList); //StoryTelling에서 별도함수 없이 textMapping사용중인데, 체크필요.
        toggle();
    }



    return (
        <>
            {/* 테이블셀에 출력되는 내용과 버튼 */}
            <Flex><Space>
              {Array.isArray(fileList) && fileList.length > 0 &&  <>
                  <Image style={{width:30, height: 40}} src={fileList[0].url}/>
                  {fileList.length > 1 &&
                      <div>x{fileList.length}</div>
                  }
                  { //다운로드 기능.//(downloadOnly) && <Button icon={<DownloadOutlined/>} onClick={() => downloadImage(file.url, file.name)}></Button>
                  }
              </>}

              {//(!readOnly && !downloadOnly) && //upload 버튼 | view버튼 | download버튼
                  <Button onClick={toggle} icon={downloadOnly? <DownloadOutlined/>: readOnly?<FolderViewOutlined/> :  <UploadOutlined/>    }>
                      {필수?'증거필수':''}
                  </Button>
              }
            </Space></Flex>

            {/* 보관함)에서 선택하는 팝업. : download일경우는 보관함 아니고 이미 올라간 파일만 보여줌. */}
            {modalOpen &&
            <ModalComponent cmId={cmId} onOk={downloadImage} modalOpen={modalOpen} upFileList={upFileList} setUpFileList={setUpFileList}
                            downloadOnly={downloadOnly} readOnly={readOnly} toggle={toggle} paramTargetList={targetList} onSelectDone={onSelectDone}
            />
            }

        </>
    )
}

export default EvidenceUploader;
