import styled from 'styled-components';
import {Row, Col, Button, Checkbox, Table, Input, Select} from "antd";

const {TextArea} = Input;

export const LayoutWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
`;

export const Header = styled.header`
    background-color: #fff;
    color: black;
    display: flex;
    align-items: center; /* 이미지와 텍스트를 수직 정렬 */
`;

export const Footer = styled.footer`
    background-color: #fff;
    color: gray;
    padding: 10px;
    text-align: right;
`;

export const Content = styled.div`
    flex: 1;
    padding: 20px;
`;

export const TitleBox = styled.div`
    border: none;
    padding-bottom: 24px;
    padding-top: 26px;
    font-weight: bold; /* Makes the text bold */
    font-size: 24px; /* Sets the font size to 26 */
`;


//화면전체 크기 test용도로도 사용가능.
export const GrayBox = styled.div`
    border: 1px solid #d9d9d9; // 회색 테두리
    padding: 24px; // 내부 여백
    border-radius: 4px; // 테두리 둥글게

    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    height: 100%;
`;

export const SmallGrayBox = styled.div`
    border: 1px solid #d9d9d9; // 회색 테두리
    border-radius: 2px; // 테두리 둥글게
    padding-right: 6px;
    padding-left: 6px;
    width: 100%;
    height: 100%;
`;


export const Title2 = styled.div`
    border: none;
    padding-bottom: 16px;
    padding-top: 24px;
    font-weight: bold; /* Makes the text bold */
    font-size: 18px; /* Sets the font size to 26 */
    text-align: left; /* Aligns text to the left */
`;

export const Title2Gray = styled.div`
    border: none;
    padding-bottom: 16px;
    padding-top: 24px;
    font-weight: 500; /* Makes the text bold */
    font-size: 18px; /* Sets the font size to 26 */
    text-align: left; /* Aligns text to the left */
`;


export const Title2_5 = styled.div`
    border: none;
    // padding-bottom: 14px;
    padding-top: 14px;
    font-weight: bold; /* Makes the text bold */
    font-size: 16px; /* Sets the font size to 26 */
`;

export const Title3 = styled.div`
    border: none;
    // padding-bottom: 14px;
    padding-top: 14px;
    font-weight: bold; /* Makes the text bold */
    font-size: 14px; /* Sets the font size to 26 */
`;


export const GrayDiv = styled.div`
    margin-top: -8px;
    margin-bottom: 14px;
    color: gray;
    font-size: 14px;
`;


export const ContentFlex = styled.div`
    margin: 20;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; /* 수직 상단 정렬 */
`;


export const UserTable = styled(Table)`
    .ant-table-thead > tr > th {
        background-color: silver; // 원하는 배경색으로 변경
        color: black; // 텍스트 색상 변경
        font-weight: 400;
    }

    .ant-table-tbody > tr > td {
        border-top: 1px solid #d9d9d9; // 셀 상단 테두리 적용
        border-bottom: 1px solid #d9d9d9; // 셀 하단 테두리 적용
    }

    /* 헤더가 hover 상태일 때 배경색 고정 */

    .ant-table-thead > tr > th:hover {
        background-color: black; /* 배경색을 투명으로 설정하거나 원하는 색상으로 변경 */
        color: white; /* 텍스트 색상을 상속받도록 설정 */
    }

    /* 헤더가 선택된 상태일 때 배경색 고정 */

    .ant-table-thead > tr > th.ant-table-column-sort {
        background-color: black; /* 배경색을 투명으로 설정하거나 원하는 색상으로 변경 */
        color: yellow; /* 텍스트 색상을 상속받도록 설정 */
    }

    /* 특정 행에 빨간색 스타일 적용 */

    .red-row {
        background-color: #eaa;
        color: black;
    }

    .red-row:hover {
        background-color: #e99;
        color: black;
    }

`;

export const PlusButton = styled(Button)`
    margin-left: 5px;
    color: blue;
    border-color: skyblue;
    font-size: 14px;
`;

export const WhiteButton = styled(Button)`
    color: #6172F3;
    border-color: #d0d5d0;
    font-size: 14px;
`;

export const WhiteRedButton = styled(Button)`
    color:${props => (props.done ? '#aaa' : '#EB5757')};
    border-color: ${props => (props.done ? '#aaa' : '#EB5757')};
    font-size: 14px;
`;


export const EmptyBox = styled.div`
    // border: 1px solid #d9d9d9; 
    padding: 16px; // 내부 여백
    border-radius: 4px; // 테두리 둥글게
    width: 100%;
    height: 100%;
`;

export const TwoColumnContainer = styled.div`
    display: flex;
    justify-content: center; /* 좌우 정렬 */
    // align-items: flex-start; /* 수직 상단 정렬 */

    align-items: ${props => (props.mobileFlag ? 'flex-start' : 'center')}; /* 수직 상단 또는 중앙 정렬 */
    flex-direction: ${props => (props.mobileFlag ? 'column' : 'row')}; /* isMobile인 경우 세로로 배치 */
`;

export const Div = styled.div`
    margin-top: 30px;
    padding-bottom: 5px;
    font-size: 14px;
`;

export const Ul = styled.ul`
    list-style-type: none;

    ::before {
        content: '•';
        color: #aaa;
        margin-right: 8px;
    }
`;

export const Li = styled.li`
    ::before {
        content: '•';
        color: #ccc;
        margin-right: 8px;
    }
`;
//box임.
// export const Div = styled.div`
//   background-color: papayawhip;
//   padding: 4em;
// `;


export const Flex = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start; /* 수직 상단 정렬 */
`;
export const FlexCenter = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start; /* 수직 상단 정렬 */
`;
export const FlexRight = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-start; /* 수직 상단 정렬 */
`;
export const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; /* 수직 상단 정렬 */

`;

export const FlexColumnCenter = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; /* 수직 상단 정렬 */
`;


export const StyledRow = styled(Row)`
    // border: 1px solid #d9d9d9; // 테두리 추가
    padding: 0px; // 콘텐츠와 테두리 간의 간격
    color: gray;
    font-size: 12px;
`;
export const EmptyCol = styled(Col)`
    padding-top: 0px; // 콘텐츠와 테두리 간의 간격
    padding-left: 10px; // 콘텐츠와 테두리 간의 간격
    padding-right: 10px; // 콘텐츠와 테두리 간의 간격
    color: gray;
    font-size: 12px;
    text-align: left;
`;

export const StyledCol = styled(Col)`
    border: 1px solid #d9d9d9; // 테두리 추가
    padding: 10px; // 콘텐츠와 테두리 간의 간격
    color: gray;
    font-size: 12px;
`;


// styled-components를 사용하여 그리드 컨테이너를 생성합니다.
export const GridContainer = styled.div`
    display: grid;
    /* 첫 번째 컬럼의 너비를 작게 설정하고 두 번째 컬럼은 1fr로 설정합니다. */
    grid-template-columns: 100px auto;
    // grid-template-columns: 10px auto;
    grid-auto-flow: row;
    gap: 2px; // 컬럼 사이의 간격을 조절합니다.
    color: gray;
    font-size: 12px;
    width: 500px;
    justify-items: start;

    // border: 1px solid #d9d9d9; /* 회색 테두리 추가 */
    // border-radius: 4px; /* 테두리 둥글게 */

`;

// 각 그리드 아이템에 스타일을 적용합니다.
export const GridItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;

    color: gray;
    font-size: 12px;

`;

export const BlackCheckbox = styled(Checkbox)`
    &&& {
        // 체크 상태에서 배경 및 테두리 색상

        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: black;
            border-color: black;
        }

        // 호버 상태에서 테두리 색상

        .ant-checkbox-wrapper:hover .ant-checkbox-inner,
        .ant-checkbox:hover .ant-checkbox-inner {
            border-color: black;
        }

        // 호버 상태에서 체크된 체크박스의 배경 및 테두리 색상

        .ant-checkbox-wrapper:hover .ant-checkbox-checked .ant-checkbox-inner,
        .ant-checkbox-checked:hover .ant-checkbox-inner {
            background-color: black;
            border-color: black;
        }
    }
`;

export const BlackButton = styled(Button)`
    background-color: black;
    color: white;
`;


export const GrayLine = styled.div`
    background-color: #ccc; /* 회색 */
    height: 1px; /* 선의 두께 */
    width: 100%; /* 전체 너비 */
`;

export const GrayDivLink = styled.div`
    margin-top: 7px;
    margin-bottom: 2px;
    color: gray;
    font-size: 12px;

    &:hover {
        color: blue; /* 마우스 오버 시 색상 변경 예시 */
        cursor: pointer; /* 마우스 커서를 포인터로 변경 */
    }
`;

export const DarkBlueDiv = styled.div`
    margin-top: 7px;
    margin-bottom: 2px;
    color: #0A174E;
    font-size: 12px;
`;

export const AutoHeightSelect = styled(Select)`
    /* 드롭다운 메뉴의 최대 높이 설정 */
    height:${props => (props.max ? '75px' : '35px')};
    
    .ant-select-dropdown {
        min-height: 14px;
        max-height: 75px; 
    }

    /* 텍스트가 넘칠 경우 자동으로 줄 바꿈 */

    .ant-select-item-option-content {
        white-space: normal;
        /* 고정 높이 대신 내용에 따라 자동 높이 설정 */
        height: auto !important;
    }

    .ant-select-selection-item {
        white-space: normal !important; // 기본적으로 white-space가 nowrap으로 설정되어 있어 이를 normal로 변경
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3; // 두 줄로 제한
        -webkit-box-orient: vertical;
    }
`;

export const LightGrayDiv = styled.div`
  padding: 9px;
  width: 100%;
  height: 35px;
  text-align: left;
  background-color: lightgray;
    border-radius: 5px; /* 모서리를 약간 둥글게 만듭니다. */
`;

export const LightLightGrayDiv = styled.div`
  padding: 9px;
  width: 100%;
  height: 80px;
  text-align: left;
  background-color: #eee;
    border-radius: 5px; /* 모서리를 약간 둥글게 만듭니다. */
`;
