import React, {useState, useEffect} from "react";
import {
    Button,
    Space,
    Input,
    Select,
    Checkbox,
    message,
    // DatePicker,
    Collapse,
    Image,
    Radio,
    Modal,
    Timeline
} from "antd";
import {AliwangwangOutlined, CaretRightOutlined, PlusOutlined, EditOutlined} from '@ant-design/icons';
import {
    TitleBox,
    Title2,
    Title3,
    Flex,
    FlexColumn,
    GrayBox,
    StyledCol,
    StyledRow, EmptyCol, EmptyBox, GrayDiv, SmallGrayBox,
    UserTable, PlusButton, QustionAreaTitle2, WhiteButton,
    AutoHeightSelect, WhiteRedButton, FlexCenter, LightGrayDiv, Title2_5, GrayLine, LightLightGrayDiv, Title2Gray,
} from "../../common/DeskComponent";
import useAxios from "../../hooks/useAxios";
import {isMobile} from 'react-device-detect';
import { useParams } from 'react-router-dom';
import PublicFileUploader from "../../components/PublicFileUploader";
import EvidenceUploader from "../../components/EvidenceUploader";
import locale from "antd/es/date-picker/locale/ko_KR";
import dayjs from "dayjs";
import {
    getBaseKey,
    getCmField, getCmFieldDepth, getCmNotationFieldKVArr, getCmNotationFieldVArr, getNotationDepth, getNotationVDepth,
} from "../../common/CmUtil";
import useModal from "../../hooks/useModal";
import {BACKEND_URL} from "../../Atoms";
import axios, { AxiosResponse } from 'axios';
import {isDevEnv} from "../../common/Util";

import {OneQuestion} from "./StoryTelling"
import groupBy from 'lodash/groupBy'
import EvidenceFolder from "./EvidenceFolder";
import DateMaskPicker from "./DateMaskPicker";
///datePicker 6줄//////////
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ko from "date-fns/locale/ko";
import './DatepickrStyles.css'
registerLocale('ko', ko)

const StoryReRequest = (props) => {

    const { counselId } = useParams();
    console.log('StoryTelling2 counsel:', counselId );

    const {axiosGet, axiosPost, axiosPut} = useAxios();
    const [messageApi, contextHolder] = message.useMessage();

    //flow, 질문, 현재cm get.
    const [askFlows, setAskFlows] = useState([]);
    //askFlow.askQuestion으로 사용. const [askQuestions, setAskQuestions] = useState([]);
    const [creditMaster, setCreditMaster] = useState();

    const [historyItems, setHistoryItems] = useState([]);

    //답변 저장용.
    const [cmFieldKv, setCmFieldKv] = useState({}); // 'cmField':'임력값'...

    //2404 추가. - 질문단위 재요청.
    const [questionMap, setQuestionMap] = useState();
    const [needAnswerQIds, setNeedAnswerQIds] = useState([]); //버튼 disable용도.
    const [questionMapUserMemos, setQuestionMapUserMemos] = useState({}); //qId:"userMemo"형태로 기록. saveOne에서 전체 저장.

    //질문보기 모달 관련.
    const [subAskModalData, setSubAskModalData] = useState({});
    const [modalOpen, setModalOpen, selected, setSelected, setModalState, toggle] = useModal();
    //const [savedHistoryIdList, setSavedHistoryIdList] = useState([]);
    //2404 saved->needAnswer로 관리방식 변경.
    const [needAnswerHIds, setNeedAnswerHIds] = useState([]);

    //재입력 모달 관련.
    const [reRequestModalData, setReRequestModalData] = useState({});
    const [requestModalOpen, setRequestModalOpen, selectedRequest, setSelectedRequest, setRequestModalState, requestToggle] = useModal();

    //증거보관함 관련.
    const [evidenceFolderFileList, setEvidenceFolderFileList] = useState([]); //[ {uid,name}, {uid,name} ]형식.

    useEffect( () => {
        if (isMobile) {
            messageApi.info('PC 화면에서 입력해주세요');
        }

        firstSearch();

    },[counselId])


    const setParentHistoryItems = (oneQuestionHistoryItems) => {
        console.log('historyItems:', oneQuestionHistoryItems);

        setHistoryItems(oneQuestionHistoryItems);
    }


    const UserReRequestTimeline = () => <FlexColumn>
        <Timeline mode={'left'} style={{marginTop:10, width:'220px'}}
                  items = { historyItems.map((oneItem, historyIdx)=> {

                      let imageList = oneItem.historyObj.evidenceField?.cmField? getCmField(creditMaster.원장, oneItem.historyObj.evidenceField.cmField + oneItem.notationPv)
                                                                               : undefined;

                      console.log('UserReRequestTimeline historyIdx, imageUrl:', historyIdx, imageList);
                      console.log('creditMaster?.reRequestMap[historyIdx]', historyIdx, creditMaster?.reRequestMap[historyIdx]);

                      return {
                          label:<div style={{width:90}}> {oneItem.label}</div>,

                          children:
                              <div style={{marginLeft:20, width:500}}>
                                  {/* 히스토리 타이틀 */}
                                  <Flex style={{fontSize:18, fontWeight:500}}>
                                      <div>{oneItem.caseTitle? oneItem.caseTitle: oneItem.historyObj.text}</div>

                                  </Flex>
                                  {oneItem.itemData &&  /* 항목 출력. */
                                  <div style={{marginTop:3, color:'#667085'}}> {oneItem.itemData} </div>
                                  }
                                  {(oneItem.historyObj.showEvidence || oneItem.historyObj.showSubAsk || creditMaster?.reRequestMap[historyIdx]) && /* 증거, 추가사실 입력 */
                                  <Flex style={{marginTop:5}}>
                                      <Space>
                                          {oneItem.historyObj.evidenceField?.cmField && imageList &&  /*히스토리 증거보기*/
                                          <div style={{marginLeft:0, width:130}}>
                                          {/*<PublicFileUploader  //imageUrl={cmFieldKv[oneItem.historyObj.evidenceField.cmField + oneItem.notationPv]} //원장.증거_i + '|1_0*/}
                                          {/*    imageUrl = {imageList}*/}
                                          {/*    downloadOnly={true} downloadName={'증거첨부'}/>*/}
                                              <EvidenceUploader  //imageUrl={cmFieldKv[oneItem.historyObj.evidenceField.cmField + oneItem.notationPv]} //원장.증거_i + '|1_0
                                                  fileList = {imageList}
                                                  readOnly={true}/>
                                          </div>
                                          }


                                          {creditMaster?.reRequestMap[historyIdx] && //creditMaster?.reRequestMap[historyIdx].filter((obj) => obj.doneFlag===false).length > 0 &&
                                              <>
                                                {(!needAnswerHIds.includes(Number(historyIdx))) ?
                                                  <Button disabled={true} type={'default'} > 이 질문의 재요청사항이 입력완료 되었습니다. </Button>
                                                  :
                                                  existDoneFlagFalse(creditMaster?.reRequestMap[historyIdx]) ?
                                                      <WhiteRedButton icon={<EditOutlined />}
                                                                   onClick={() => openSubAskModal(historyIdx, //oneItem.askFlowId,
                                                                       oneItem.label + '일 ' + (oneItem.caseTitle?oneItem.caseTitle:oneItem.historyObj?.text ),
                                                                       oneItem.notationPv.substring(1),  //notationV가 필요.
                                                                   )}
                                                      > 입력하신 사항에 오류가 있는 듯 해요. 내용을 다시 확인 해주세요. </WhiteRedButton>
                                                  : <></>
                                                }
                                              </>
                                          }


                                      </Space>

                                  </Flex>
                                  }

                                  {(historyIdx !== historyItems.length -1) && <br/> }
                              </div>
                      };
                  })}
        />
    </FlexColumn>

    const firstSearch = async() => {

        //질문목록 가져오기. 3가지 한꺼번에 받음.
        let flowAskCmDto  = await axiosGet(`/api/ipoUser/askFlow/${counselId}`);

        console.log('cm', flowAskCmDto.cm);
        console.log('userAskFlows', flowAskCmDto.userAskFlows);

        setAskFlows(flowAskCmDto.userAskFlows);
        setCreditMaster(flowAskCmDto.cm);


        //증거보관함 추가 /////////////////////////
        const evidenceFolderFileList = getCmField(flowAskCmDto.cm.원장, '원장.채권자.증거보관함' );
        console.log('evidenceFolderFileList:', evidenceFolderFileList);
        if (evidenceFolderFileList) {
            setEvidenceFolderFileList(evidenceFolderFileList);
        }
        //증거보관함용 kv추가필요.
        setCmFieldKv(prev => ({...prev, ['원장.채권자.증거보관함']:evidenceFolderFileList?evidenceFolderFileList:[]}) )


        console.log('flowAskCmDto.cm reRequestMap:', flowAskCmDto.cm?.reRequestMap);
        console.log('flowAskCmDto.cm reRequestMap:', flowAskCmDto.cm?.reQuestionMap);
        //질문단위 재요청
        if (existDoneFlagFalseAllQuestionMap(flowAskCmDto.cm?.reQuestionMap) ) {
            setQuestionMap(flowAskCmDto.cm?.reQuestionMap);
            setNeedAnswerQIds(getAllQuestionMapQId(flowAskCmDto.cm?.reQuestionMap));
        }
        //히스토리방식 재요청
        if (Array.isArray(Object.keys(flowAskCmDto.cm.reRequestMap))) {
            let hIds = Object.keys(flowAskCmDto.cm.reRequestMap);
            let hIdArr = [];
            for (const key of hIds) {
                if (existDoneFlagFalse(flowAskCmDto.cm.reRequestMap[key]) ) {
                    hIdArr.push(Number(key));
                }
            }
            setNeedAnswerHIds(hIdArr);
        }

    }

    const existDoneFlagFalseAllQuestionMap = (questionMap) => {
        console.log('existDoneFlagFalseAllQuestionMap');
        let existFalse = false;
        let keys = Object.keys(questionMap);
        for (const key of keys) {
            if (existDoneFlagFalse(questionMap[key])){
                existFalse = true;
            }
        }
        console.log('existDoneFlagFalseAllQuestionMap FALSE');
        return existFalse;
    }

    const getQuestionNoFromQuestionMapByQid = (qId) => {
        let  objArr = questionMap[qId];
        return objArr.filter((obj) => obj.doneFlag==false) [0].questionNo;
    }

    const getAllQuestionMapQId = (questionMap) => {
        let ret=[];
        let keys = Object.keys(questionMap);
        for (const key of keys) {
            if (existDoneFlagFalse(questionMap[key])){
                ret.push(Number(key)); //qId가 키임.
            }
        }
        return ret;
    }


    //미사용: historyItem별 질문들 필터링. true/false : vwsBoAdmin.IpoViewerReview2ndProc.jsx에서 복사해옴.
    //const checkQuestionByFieldAndPv = (historyItem, flow, askFlows) =>



    //flow따라가면서 현재 질문까지 저장.
    const saveOneHistoryId = async(hId, paramCmFieldKv, paramCmReRequestMap) => {
        console.log('saveOneHistoryId', hId);

        let savingCmFieldKv = (paramCmFieldKv)?paramCmFieldKv:cmFieldKv;
        let savingCmReRequestMap = (paramCmReRequestMap)?paramCmReRequestMap:creditMaster.reRequestMap;
        //doneFlag이 false인게 있는지 체크.

        let notAnswerdQno = 0;

        // Array.from(groupingOneHistoryRequestMapByQid(creditMaster?.reRequestMap[subAskModalData.hId])).map( ([qIdStr,objArr], idx) => {
        //    if (existDoneFlagFalse(objArr) ) {
        //        notAnswerdQno = (idx+1);
        //    }
        // });
        //savingCmReRequestMap 이용으로 변경
        Array.from(groupingOneHistoryRequestMapByQid(savingCmReRequestMap[subAskModalData.hId])).map( ([qIdStr,objArr], idx) => {
            if (existDoneFlagFalse(objArr) ) {
                notAnswerdQno = (idx+1);
            }
        });

        if (notAnswerdQno) {
            messageApi.info( notAnswerdQno + '번 히스토리 관련 질문이 다 입력되지 않았습니다.') ;
            return;
        }


        //1.reRequestMap저장.
        // let retInt  = await axiosPost(`/api/ipoUser/askFlow/reRequestMap/${creditMaster.cmId}`, creditMaster.reRequestMap);

        //2403-savingCmReRequestMap으로 변경 중.
        let retInt  = await axiosPost(`/api/ipoUser/askFlow/reRequestMap/${creditMaster.cmId}`, savingCmReRequestMap);
        //2.cmFieldKv저장
        let retInt2  = await axiosPost(`/api/ipoUser/askFlow/cmFieldKv/${creditMaster.cmId}`, savingCmFieldKv);


        console.log('hId, saveOneHistoryId cm.rrm:', savingCmReRequestMap);
        console.log('hId, saveOneHistoryId cmkv:', savingCmFieldKv);

        //미사용.
        // if (closeModal) {
        toggle();
        // }


        if (hId !== undefined && typeof Number(hId) === "number") {
            setNeedAnswerHIds(prev => prev.filter((item) => item !== Number(hId)));
            //setSavedHistoryIdList(prev => [...prev, Number(hId)]);
        }

        if (retInt > 0 && retInt2 > 0) {
            alert('저장되었습니다.');
        }

        return retInt;

    }

    const saveAllIpoFinish = async() => {

        //필요없음: let retInt = await saveOneHistoryId(false, 0); //혹시 몰라서 한번더 호출?
        //if (retInt > 0) {
            //messageApi.info('저장되었습니다.');
        //}

        let retInt2 = await axiosPost(`/api/ipoUser/askFlow/ipoDone/${creditMaster.cmId}`, {value:null});
        if (retInt2 > 0) {
            alert('저장되었습니다.');
        }


        //myPage refresh
        window.opener.document.location.href = window.opener.document.URL;

        window.close();

        // let retInt  = await axiosPost(`/api/ipoUser/askFlow/cmFieldKv/${creditMaster.cmId}`, cmFieldKv);
        // if (retInt > 0) {
        //     //messageApi.info('저장되었습니다.');
        //
        //     let retInt2  = await axiosPost(`/api/ipoUser/askFlow/ipoDone/${creditMaster.cmId}`);
        //     if (retInt2 > 0) {
        //         alert('저장되었습니다.');
        //     }
        //
        //     //myPage refresh
        //     window.opener.document.location.href = window.opener.document.URL;
        //
        //     window.close();
        // }
    }



    const getQuestionByQuestionId = (qId) => {
        const oneFlow = askFlows.find ((flow)=> flow.askQuestionId === qId);

        return oneFlow?.askQuestion;
    }

    //param: notationV = '0' or '1_2' 등.
    const openSubAskModal = (hId, colTitle, notationV ) => {

        //const modalAskFlow = askFlows.find((flow) => flow._id === askFlowId);
        let questions = creditMaster.reRequestMap[hId].map( (obj) => getQuestionByQuestionId(obj.questionId) );
        console.log('questions:', questions);

        setSubAskModalData( {hId:hId, questions:questions, title: colTitle,  notationV:(notationV)?notationV:undefined} );
        toggle();

    }

    const openReRequestInputModal = (hId, qId, qNo, objArr, 채권명Field) => {
        console.log('openReRequestInputModal:', objArr);

        setReRequestModalData({hId, qId, qNo, objArr, 채권명Field});
        requestToggle();
    }


    const findIndexOfQidFromFlow = (qId) => {
        const flowIndex = askFlows.findIndex((flow) => flow.askQuestion._id == qId );
        console.log('sort:(qId, flowIndex) = ', qId, flowIndex);
        return flowIndex;
    }


    const existDoneFlagFalse = (rrObjArr) => {
        if (Array.isArray(rrObjArr)) {
            for (let rrObj of rrObjArr) {
                if (rrObj.doneFlag === false) {
                    return true;
                }
            }
        }
        return false;
    }

    // param { 58질문: [rrObj, rrObj], 57질문: [rrObj] } => return { 57질문: [rrObj, rrObj], 58질문: [rrObj] }
    const orderByAskFlows = (groupObj) => {

        //{ 57질문: [rrObj, rrObj], 58질문: [rrObj] } => 58,57 로 변경.
        let sortedKeys = Object.keys(groupObj).sort((a, b) => findIndexOfQidFromFlow(a) - findIndexOfQidFromFlow(b));
        console.log('sortedKeys:', sortedKeys);

        //필터링1. 으로 모두 doneFlag=true인 질문자체를 제거
        sortedKeys = sortedKeys.filter((qId) => existDoneFlagFalse(groupObj[qId]));


        let sortedMap = new Map(sortedKeys.map(key => [key, groupObj[key]]));
        console.log('sortedMap:', sortedMap);

        return sortedMap;

    }

    const groupingOneHistoryRequestMapByQid = (oneRequestMap) => {

        //{ 57질문: [rrObj, rrObj], 58질문: [rrObj] }
        const questionRequestGroupObj = groupBy(oneRequestMap, 'questionId')


        //orderByAskFlows 함수에서 순서유지를 위해 map으로 변환해서 리턴 중.

        console.log('groupBy:', questionRequestGroupObj);
        return orderByAskFlows(questionRequestGroupObj);
    }


    const setAndGetOriginalAnswerObj = (rrModalData, idx, 답변유형) => {
        //obj를 찾아야함.
        let obj = rrModalData.objArr[idx];

        //saveOne에도 동일한 루틴 있음.
        let cmField = obj.tableCmField;
        let pv = obj.notationPv;

        if (!cmField) {
            console.log('cmField null ERROR');
            return '';
        }
        //obj.tableCmField가 depth1이고, obj.notationPv가 depth2이면 pv->depth 1으로 만들어야 함.
        if (getCmFieldDepth(cmField) === 1 && getNotationVDepth(pv) === 2) {
            pv = pv.substring(0, pv.indexOf('_'));
        }
        let cmVField = cmField + pv;

        //웥장에서 값 가져오기
        let originalAnswer = getCmField(creditMaster.원장, cmVField);

        if (답변유형 === '채권들 선택') {
            return originalAnswer;
        }


        //obj에 세팅: 그렇지만 '예|4'=> 예로 변경.
        if (typeof originalAnswer ==='string' && originalAnswer.indexOf('|') > 0) {
            originalAnswer = originalAnswer.substring(0, originalAnswer.indexOf('|'));
        }
        obj.originalAnswer = originalAnswer;

        if (typeof originalAnswer ==='string' || !originalAnswer?.date) {
            return originalAnswer
        }
        //todo? dateMask처리.  [file]은 690라인에서 처리중.
        return originalAnswer.date + ((originalAnswer.mask===1)?' 연도만 앎':(originalAnswer.mask===2)?' 연월만 앎':''); //dateMask처리.
        //setReRequestModalData({...rrModalData});
    }

    //{hId, qId, qNo, objArr}: reRequestModalData
    const handleObjInputChange = (rrModalData, idx, e) => {
        let objArr = JSON.parse( JSON.stringify(rrModalData.objArr));
        //obj를 찾아야함.
        //let obj = objArr[idx];
        objArr[idx][e.target.name] = e.target.value;
        objArr[idx].todo = true;//임시전달

        console.log('handleObjInputChange:', objArr[idx]);
        // setReRequestModalData({...rrModalData});
        setReRequestModalData(prev => ({...prev, objArr:objArr})); //이라인이 문제임.
    }
    const handleFloatInputChange = (rrModalData, idx, e) => {
        let objArr = JSON.parse( JSON.stringify(rrModalData.objArr));
        //obj를 찾아야함.
        //let obj = objArr[idx];
        objArr[idx][e.target.name] = Number(e.target.value)*100;
        objArr[idx].todo = true;//임시전달

        console.log('handleFloatInputChange:', Number(e.target.value)*100, objArr[idx]);
        // setReRequestModalData({...rrModalData});
        setReRequestModalData(prev => ({...prev, objArr:objArr})); //이라인이 문제임.
    }

    const handleReactDatePickerChange = (rrModalData, idx, datesString) => {

        let value = datesString? dayjs(datesString).format('YYYY-MM-DD'): '';

        let objArr = JSON.parse( JSON.stringify(rrModalData.objArr));
        //obj를 찾아야함.
        //let obj = objArr[idx];
        objArr[idx].reAnswer = value;
        objArr[idx].todo = true;//임시전달

        console.log('hId: handleObjValueChange1:', objArr[idx].reAnswer, value); //value ok, but obj는 안바뀜.
        console.log('hId: handleObjValueChange2:', value, objArr[idx]);
        console.log('hId: handleObjValueChange2:',  idx);

        setReRequestModalData(prev => ({...prev, objArr:objArr})); //이라인이 문제임.
    }

    const handleObjValueChange = (rrModalData, idx, value) => {

        let objArr = JSON.parse( JSON.stringify(rrModalData.objArr));
        //obj를 찾아야함.
        //let obj = objArr[idx];
        objArr[idx].reAnswer = value;
        objArr[idx].todo = true;//임시전달

        console.log('hId: handleObjValueChange1:', objArr[idx].reAnswer, value); //value ok, but obj는 안바뀜.
        console.log('hId: handleObjValueChange2:', value, objArr[idx]);
        console.log('hId: handleObjValueChange2:',  idx);

        setReRequestModalData(prev => ({...prev, objArr:objArr})); //이라인이 문제임.
    }

    const handle채권들선택Change =(rrModalData,  idx, checked, 채권idx) => {
        // let obj = rrModalData.objArr[idx];
        // obj['reAnswer'] = (checked)? pushIdx( obj['reAnswer'], 채권idx) : popIdx(obj['reAnswer'], 채권idx);
        // obj['todo'] = true;//임시전달
        // setReRequestModalData({...rrModalData});

        //안전하게 변경.
        let objArr = JSON.parse( JSON.stringify(rrModalData.objArr));
        let obj = objArr[idx];
        let value = (checked)? pushIdx( obj['reAnswer'], 채권idx) : popIdx(obj['reAnswer'], 채권idx);

        //let obj = objArr[idx];
        objArr[idx].reAnswer = value;
        objArr[idx].todo = true;//임시전달

        setReRequestModalData(prev => ({...prev, objArr:objArr})); //이라인이 문제임.
    }


    const get답변유형SelectItems = (rrModalData, idx) => {
        let qId = rrModalData.qId;
        let askQuestion = getQuestionByQuestionId(qId);
        if (Array.isArray(askQuestion?.표질문)) {
            //obj를 찾아야함.
            let obj = rrModalData.objArr[idx];
            //let cmField = obj.tableCmField;
            for (const col of askQuestion.표질문) {
                if (col.cmField === obj.tableCmField) {
                    return col.selectBoxQs;  //date,text.selectBox
                }
            }
        }
        return [];
    }

    const get답변유형OfObj = (rrModalData, idx) => {
        let qId = rrModalData.qId;
        let askQuestion = getQuestionByQuestionId(qId);
        if (Array.isArray(askQuestion?.표질문)) {
            //obj를 찾아야함.
            let obj = rrModalData.objArr[idx];
            if (obj.colTitle==='증거첨부') { //////////////증거첨부는 별도로 개발됨.
                return '[file]';
            }
            //let cmField = obj.tableCmField;
            for (const col of askQuestion.표질문) {
                if (col.cmField === obj.tableCmField) {
                    console.log('hId : get답변유형OfObj:', col.답변유형);
                    return col.답변유형;  //date,text.selectBox
                }

            }
        }
        return "";
    }

    //기존답변 선택
    const handleRequestModalCheckboxOriginal = (rrModalData, idx, checked) => {
        if (checked) {
            if (['date','dateMask'].includes(get답변유형OfObj(rrModalData, idx))) return;
            //obj를 찾아야함.
            let obj = rrModalData.objArr[idx];
            obj['reAnswer'] = obj.originalAnswer;

            console.log('handleObjInputChange:', obj);
            setReRequestModalData({...rrModalData});
        }
    }

    //변호사답변 선택
    const handleRequestModalCheckboxRecommend = (rrModalData, idx, checked) => {
        if (checked) {
            if (['[file]','date','dateMask'].includes(get답변유형OfObj(rrModalData, idx))) {
                messageApi.info('변호사 제시답변을 참고해서 직접 선택해 주세요');
                return;
            }
            //obj를 찾아야함.
            let obj = rrModalData.objArr[idx];
            obj['reAnswer'] = obj.opRecommend;

            console.log('hId, handleObjInputChange:', obj);
            setReRequestModalData({...rrModalData});
        }
    }

    //채권1 추가
    const pushIdx = (str, 채권idx) => {
        let arr = (!str)?[]: str.split(','); //값이 없는데 split하면 ''가 들어가는 문제가 있음.
        if (!arr.includes( String(채권idx)) ) {
            arr.push(채권idx); //추가
        }
        return arr.join(",");
    }
    const popIdx = (str, 채권idx) => {
        let arr = (!str)?[]: str.split(','); //값이 없는데 split하면 ''가 들어가는 문제가 있음.
        console.log('popIdx arr1', arr, 채권idx);
        // if (arr.includes( String(채권idx)) ) {
            arr = arr.filter( (item) => item != 채권idx); //제거
        // }

        console.log('popIdx arr2', arr);

        return arr.join(",");
    }


    const inputDoneOneQuestionModal = async () => {

        //rrModalData -> reRequestMap에 세팅 필요.
        console.log('hId rrMd', reRequestModalData );    //{hId, qId, objArr[]} 240228: 여기가 틀림.  405라인에서 세팅한게 안 들어옴.
        console.log('hId', creditMaster.reRequestMap ); //hId:[obj2Arr(questionId포함)], hid2:[obj2Arr(questionId포함)]
        console.log('hId', cmFieldKv);                  //[ cmVField:value ]
        console.log('hId', creditMaster );
        //let askQuestion = getQuestionByQuestionId(qId);


        // let cmReRequestMap = {...creditMaster.reRequestMap};
        let cmReRequestMap = JSON.parse(JSON.stringify(creditMaster.reRequestMap));

        //동기화가 잘 안되서 임시전달
        let savingCmFieldKv;

        //1. reRequestMap 메모리기록 .
        if (Array.isArray(reRequestModalData.objArr)) {
            let hId = reRequestModalData.hId;
            let qId = reRequestModalData.qId;
            //let askQuestion = getQuestionByQuestionId(qId);

            for (const rmObj of reRequestModalData.objArr) {
                if (!rmObj.todo) {
                    console.log('pass hId:', rmObj, reRequestModalData.objArr); //정확한 수정요청사항만 reAnswer 세팅필요.
                    continue;
                }

                //OLD let rrMapArr = creditMaster.reRequestMap[hId];

                //let cmReRequestMap = {...creditMaster.reRequestMap};
                let rrMapArr = cmReRequestMap[hId];

                //find Obj
                let targetObj = undefined;
                if (Array.isArray(rrMapArr)) {
                    targetObj = rrMapArr.find( (rrObj) => rrObj.doneFlag===false && rrObj.questionId == qId && rrObj.tableCmField === rmObj.tableCmField);
                    //targetObj = rrMapArr.find( (rrObj) =>  rrObj.questionId == qId && rrObj.tableCmField === rmObj.tableCmField);
                }
                console.log('target hId:', targetObj, rrMapArr, qId);

                if (targetObj) { //항상0번째꺼 찾는 문제가 있음.  노출자체를 차단. && targetObj.doneFlag === false) {
                    // creditMaster.reRequestMap 수정. setStatus는 안해도 되나.
                    console.log('** toFix source hId', rmObj ); //wrong.

                    targetObj.originalAnswer = rmObj.originalAnswer;
                    targetObj.reAnswer = rmObj.reAnswer;
                    targetObj.userMemo = rmObj.userMemo;
                    targetObj.doneFlag = true;
                    console.log('** toFixValue hId', rmObj );

                    console.log('** fixed hId', cmReRequestMap );
                    //질문창이 사라져서 한번 막아봄.
                    setCreditMaster(prev => ({...prev, reRequestMap:cmReRequestMap}));
                }else {
                    console.log('** targetObj not found ', rrMapArr,  );
                }

                //2. cmFieldKv 메모리기록 ///////////////////////////
                //setAndGetOriginalAnswerObj에도 동일한 루틴 있음.
                let cmField = rmObj.tableCmField;
                let pv = rmObj.notationPv;

                //obj.tableCmField가 depth1이고, obj.notationPv가 depth2이면 pv->depth 1으로 만들어야 함.
                if (getCmFieldDepth(cmField) === 1 && getNotationVDepth(pv) === 2) {
                    pv = pv.substring(0, pv.indexOf('_'));
                }
                let cmVField = cmField + pv;
                console.log('hId: cmFieldKV 세팅:',cmVField, rmObj.reAnswer);
                savingCmFieldKv = {...cmFieldKv, [cmVField]: rmObj.reAnswer }; //임시전달.

                setCmFieldKv(prev => ({...prev, [cmVField]: rmObj.reAnswer }) );

            }
        }
        setReRequestModalData({})
        requestToggle();

        //모든 질문 입력 다끝났으면 자동 저장. 빈화면이 남아서 보기싫어서 수행
        let hId = reRequestModalData.hId;
        if (!existDoneFlagFalse(cmReRequestMap[hId])) {
            await saveOneHistoryId(hId, savingCmFieldKv, cmReRequestMap);
        }
    }

    if (!creditMaster) return null;

    const addQuestionMapUserMemo = (qId) => {
        let memo = window.prompt(" 운영자에게 메모/요청사항 입력:");
        if (memo) {
            setQuestionMapUserMemos(prev => ({...prev, [qId]:memo}));
        }
    }

    const saveOneQuestion = async (qId) => {
        console.log('NeedAnswerQIds, cmFieldKv:', needAnswerQIds, cmFieldKv);
        let retInt  = await axiosPost(`/api/ipoUser/askFlow/cmFieldKv/${creditMaster.cmId}`, cmFieldKv);
        //update doneFlag.
        let retInt2  = await axiosPut(`/api/ipoUser/reQuestionMapDone/${creditMaster.cmId}/${qId}`, questionMapUserMemos);

        if (retInt && retInt2) {
            setNeedAnswerQIds(prev => prev.filter((item)=> item !== qId));
            messageApi.info('저장되었습니다.');
        }
    }
    return (
        <div style={{margin:24}}>
            {contextHolder}

            <FlexColumn>
                <EvidenceFolder paramEvidenceHide={true} cmId={creditMaster.cmId} cmFieldKv={cmFieldKv} setCmFieldKv={setCmFieldKv} paramEvidenceFolderFileList={evidenceFolderFileList} />


                <TitleBox> 재요청된 정보를 입력/수정해 주세요. </TitleBox>

                {questionMap &&
                    <FlexColumn>
                        <Title2Gray> 답변 수정요청 사항 </Title2Gray>

                        <GrayBox>
                            <FlexColumn>
                            {askFlows && askFlows.filter((flow) => getAllQuestionMapQId(questionMap).includes(flow.askQuestion?._id)).map( (flow, idx) =>
                                <FlexColumn>
                                    <OneQuestion reQuestionMap={true} idx={getQuestionNoFromQuestionMapByQid(flow.askQuestion?._id)-1}  askQuestion={flow.askQuestion} cm={creditMaster} cmFieldKv={cmFieldKv} setCmFieldKv={setCmFieldKv} saveAll={()=>{}} openSubAskModal={openSubAskModal}/>

                                    {Array.isArray( questionMap[flow.askQuestion._id] ) &&
                                        questionMap[flow.askQuestion._id].filter( (obj) => obj.doneFlag != true).map( (obj, idx) =>
                                            <>
                                            <GrayDiv style={{margin:16, marginBottom:-10}}> &middot; 아래 안내를 참고해서 위 답변을 수정해 주세요.</GrayDiv>
                                            <div style={{backgroundColor:'lightgray', borderRadius:5, margin:16, padding:16 }}>
                                                 <FlexColumn> <Space direction={'vertical'}>
                                                    <Flex><div style={{fontSize:14, marginTop:5}}>안내문구 : &nbsp;</div> <Input readOnly={true} type={'text'} value={obj.guideText} style={{width: 500}}/>
                                                    </Flex>
                                                    <Flex><div style={{fontSize:14, marginTop:5}}>추천답변 : &nbsp;</div> <Input  readOnly={true} type={'text'} value={obj.opRecommend} style={{width: 500}}/>
                                                    </Flex>
                                                     <Flex><div style={{fontSize:14, marginTop:5}}>메모입력 : &nbsp;</div>
                                                         {questionMapUserMemos[flow.askQuestion._id] &&
                                                             <div style={{fontSize:14, margin:5}}> {questionMapUserMemos[flow.askQuestion._id]} </div>
                                                         }
                                                         <Button onClick={()=>addQuestionMapUserMemo(flow.askQuestion._id)}> 운영자에게 메모/요청사항</Button>
                                                     </Flex>
                                                </Space></FlexColumn>
                                            </div>
                                            </>
                                    )}

                                    { //이전요청내용 출력. (자동펼지기로 진행)
                                        Array.isArray( questionMap[flow.askQuestion._id] ) &&  questionMap[flow.askQuestion._id].filter( (obj) => obj.doneFlag === true).length > 0 &&
                                        <div style={{marginLeft: 16, textAlign:'left'}}>
                                            <Collapse  defaultActiveKey={undefined} bordered={false} size={'small'} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                                       items = { [{
                                                           key: 1, label: ' 이전 재요청 이력 ', children:
                                                               questionMap[flow.askQuestion._id].filter( (obj) => obj.doneFlag === true).map ((obj) =>
                                                                   <Flex><Space>
                                                                       <FlexColumn> <Space direction={'vertical'}>
                                                                           <div>안내문구: &nbsp; {obj.guideText}</div>
                                                                           <div>추천답변: &nbsp; {obj.opRecommend}</div>
                                                                           <div>고객 메모: &nbsp; {obj.userMemo}</div>
                                                                           <br/>
                                                                       </Space></FlexColumn>
                                                                   </Space></Flex>
                                                               )
                                                       }]}
                                            />
                                        </div>
                                    }

                                    <EmptyBox>
                                        <Button disabled={!needAnswerQIds.includes(flow.askQuestion._id)} onClick={()=>saveOneQuestion(flow.askQuestion._id)} style={{backgroundColor: needAnswerQIds.includes(flow.askQuestion._id)?'#ffd118':'#ddd', color:'black'}} type={'primary'}> 수정완료 </Button>
                                    </EmptyBox>
                                    <br/>
                                </FlexColumn>
                            )}
                            </FlexColumn>
                        </GrayBox>


                    </FlexColumn>
                }




                {askFlows && askFlows.filter((flow) => flow.askQuestion?.답변형식==='히스토리')
                    .filter((flow) => flow.type!=='subAsk') .map( (flow, idx) =>
                        //setParentHistoryItems 을 전달하면 그리지 않음.(제목만 출력함)
                        <OneQuestion VIEW_MODE={true} idx={idx} setParentHistoryItems={setParentHistoryItems} askQuestion={flow.askQuestion} cm={creditMaster} cmFieldKv={cmFieldKv} setCmFieldKv={setCmFieldKv} saveAll={()=>{}} openSubAskModal={openSubAskModal}/>
                )}

                <UserReRequestTimeline/>

                <EmptyBox>
                    <Button disabled={(needAnswerQIds.length !==0 || needAnswerHIds.length !==0)} style={{backgroundColor: (needAnswerQIds.length===0 && needAnswerHIds.length===0)?'#ffd118':'#ddd', color:'black'}} type={'primary'} onClick ={saveAllIpoFinish} > 모든질문 입력완료 </Button>
                </EmptyBox>
            </FlexColumn>

            {/* 질문 */}
            <Modal
                // title={ '[ ' + subAskModalData.title + ' 추가 질문 ]'}
                title={ <div style={{ fontSize: '18px' }}> {'[ ' + subAskModalData.title + ' ] 관련 재요청 사항 '} </div>}
                visible={modalOpen}
                onCancel={toggle}
                footer={null}
                destroyOnClose={true}
                getContainer={false}
                bodyStyle={{padding:0}}
                width={1300}
                maskClosable={false}
            >
                <>
                    {//askFlows.filter((oneFlow) => checkQuestionByFieldAndPv( historyItems[subAskModalData.hId] , oneFlow, askFlows, historyItems[subAskModalData.hId]?.notationPv)).map((flow,idx)=>
                    creditMaster?.reRequestMap[subAskModalData.hId] &&

                        //{ 57질문: [rrObj, rrObj], 58질문: [rrObj] }
                        //순서가 뒤바뀜. Object.keys=>Map으로 변환함. -> Map을 다시 Array로 변환해서 .map([k,v]=>)
                        Array.from(groupingOneHistoryRequestMapByQid(creditMaster?.reRequestMap[subAskModalData.hId])).map( ([qIdStr,objArr], idx) => {

                           //질문 한개에 2개질문이면 q 하나로 합치기. reRequestCmFields 를 기준으로 합친버전.
                            // key =qId, v = groupingOneHistoryRequestMapByQid(creditMaster?.reRequestMap[subAskModalData.hId])[qId]
                        let 대여Item = historyItems.filter((item) => item.historyObj?.crType==='대여');
                        let 대여HistoryObj = 대여Item[0].historyObj;
                        let 채권명Field = 대여HistoryObj?.원장입력[0]?.cmField;

                        console.log('재요청 param 채권명Field: ', 대여Item, 대여HistoryObj, 채권명Field);

                        return <FlexColumn>
                            <OneQuestion VIEW_MODE={true} reRequestInputModal={()=>openReRequestInputModal(subAskModalData.hId, Number(qIdStr), idx+1, objArr, 채권명Field )} reRequestObjArr={objArr} selectedPv={historyItems[subAskModalData.hId].notationPv} idx={idx} askQuestion={getQuestionByQuestionId(Number(qIdStr))} cm={creditMaster} cmFieldKv={cmFieldKv} setCmFieldKv={setCmFieldKv} saveAll={()=>{}}/>

                            <div>
                            <Collapse style={{marginLeft:8}} bordered={false} size={'small'} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                      items={ [{
                                          key: 1, label: '재요청/답변 이력 보기', children:

                                              creditMaster.reRequestMap[subAskModalData.hId].filter((obj) => obj.questionId === Number(qIdStr)) //todo 중요: 2차원일때는 항상 필터링 필요
                                                  .map((obj, idx) =>
                                                      obj.doneFlag? <Flex><Space>
                                                          <div style={{width: 150, marginBottom:100}}> 항목: {obj.colTitle} </div>

                                                          {/*{flow.askQuestion.표질문.find((col)=>col.cmField===obj.tableCmField)?.title} </div>*/}

                                                          <FlexColumn> <Space direction={'vertical'}>
                                                              <div >안내문구: &nbsp; {obj.guideText}</div>
                                                              <div style={{textAlign:'left'}}>추천답변: &nbsp; {obj.opRecommend}</div>
                                                              <Flex>이전답변: &nbsp; {obj.colTitle === '증거첨부' ?
                                                                  // <PublicFileUploader
                                                                  //     imageUrl={obj.originalAnswer} downloadOnly={true} downloadName={'증거첨부'}/>
                                                                  <EvidenceUploader  //ERR fileList={cmFieldKv[oneItem.historyObj.evidenceField.cmField + oneItem.notationPv]}  //evidenceFileList={getCmField(cm.원장, '원장.채권자.증거보관함')}
                                                                    fileList={obj.originalAnswer}
                                                                    readOnly={true} />

                                                                  : <div> {(obj instanceof Object && obj.originalAnswer)?obj.originalAnswer.date: obj.originalAnswer} </div>
                                                                  }
                                                              </Flex>

                                                              <Flex>새 답변: &nbsp; {obj.colTitle === '증거첨부' ?
                                                                  // <PublicFileUploader
                                                                  //     imageUrl={obj.reAnswer} downloadOnly={true} downloadName={'증거첨부'}/>
                                                                  <EvidenceUploader  //ERR fileList={cmFieldKv[oneItem.historyObj.evidenceField.cmField + oneItem.notationPv]}  //evidenceFileList={getCmField(cm.원장, '원장.채권자.증거보관함')}
                                                                      fileList={obj.reAnswer}
                                                                      readOnly={true} />
                                                                  : <div> { (obj instanceof Object && obj.reAnswer)? obj.reAnswer.date:obj.reAnswer } </div>
                                                                  }
                                                              </Flex>
                                                              <div style={{textAlign:'left'}}>고객 메모: &nbsp; {obj.userMemo}</div>
                                                              <br/>
                                                          </Space></FlexColumn>
                                                      </Space></Flex>
                                                      :
                                                      <></>
                                                  )

                                      }]}/>
                            </div>
                        </FlexColumn>
                       }
                      )

                    }
                    <br/>
                    <EmptyBox>
                        <Button style={{backgroundColor:'#ffd118', color:'black'}} type={'primary'} onClick ={()=>saveOneHistoryId(subAskModalData.hId)} > 저 장 </Button>
                    </EmptyBox>
                </>
            </Modal>


            {/* 재입력 */}
            <Modal
                // title={ '[ ' + subAskModalData.title + ' 추가 질문 ]'}
                title={ <div style={{ fontSize: '18px' }}> {`[ ${subAskModalData.title} ]  ${reRequestModalData.qNo}번 질문 재입력 `} </div>}
                visible={requestModalOpen}
                onCancel={requestToggle}
                footer={null}
                destroyOnClose={true}
                getContainer={false}
                bodyStyle={{padding:0}}
                width={800}
                maskClosable={false}
            >
                <FlexCenter><FlexColumn style={{width:500}}>
                    { //(hId, qId, qNo, objArr) : reRequestModalData
                     reRequestModalData.objArr && reRequestModalData.objArr.map((obj, idx) => {

                        console.log('reRequestModalData', reRequestModalData);

                        return obj.doneFlag? <></>
                            :
                            <>
                            <Title2> 항목명: {obj.colTitle} </Title2>
                                <LightLightGrayDiv>[안내] {obj.guideText} </LightLightGrayDiv>

                            <Flex><Space>
                                <Title2_5> 기존 답변</Title2_5>
                                {/*<Checkbox style={{marginTop:15}} name={'originalAnswer'} onChange={(e)=>handleRequestModalCheckboxOriginal(reRequestModalData, idx, e.target.checked)}/>*/}
                            </Space></Flex>

                            {(get답변유형OfObj(reRequestModalData, idx) === '[file]')?
                                // <PublicFileUploader imageUrl={setAndGetOriginalAnswerObj(reRequestModalData, idx)} readOnly={true}
                                //                     onChange={(returnUrl) => handleObjValueChange(reRequestModalData, idx, returnUrl)}
                                //                     buttonText={'파일 선택'} name={'증거첨부'}/>
                                <EvidenceUploader  //ERR fileList={cmFieldKv[oneItem.historyObj.evidenceField.cmField + oneItem.notationPv]}  //evidenceFileList={getCmField(cm.원장, '원장.채권자.증거보관함')}
                                    fileList={setAndGetOriginalAnswerObj(reRequestModalData, idx)}
                                    readOnly={true} />
                             :
                             (get답변유형OfObj(reRequestModalData, idx) === '채권들 선택')?
                                    <LightGrayDiv> {setAndGetOriginalAnswerObj(reRequestModalData, idx, get답변유형OfObj(reRequestModalData, idx))   //"0", "", "0,1" 등 String
                                        .split(',').map ( (채권idx) =>
                                            !isNaN(채권idx)? <> {'채권' + (Number(채권idx)+1) + ' 선택됨 ' } </>
                                            :
                                            <></>
                                        )
                                    } </LightGrayDiv>
                             :
                               <LightGrayDiv> {setAndGetOriginalAnswerObj(reRequestModalData, idx)} </LightGrayDiv>
                            }

                            <Flex><Space>
                                <Title2_5> 변호사 제시 답변</Title2_5>
                                {/*<Checkbox style={{marginTop:15}} name={'originalAnswer'} onChange={(e)=>handleRequestModalCheckboxRecommend(reRequestModalData, idx, e.target.checked)}/>*/}
                            </Space></Flex>
                            <LightGrayDiv> {obj.opRecommend}</LightGrayDiv>

                            <Title2_5> 새로운 답변 (입력해 주세요) </Title2_5>
                            {
                                (get답변유형OfObj(reRequestModalData, idx) === 'float')?
                                    <Input disabled={obj.doneFlag} type={'text'} style={{backgroundColor:'#f9f9f9'}}  name={'reAnswer'} value={obj.reAnswer? Number(obj.reAnswer)/100:''} placeholder={'입력해 주세요'}
                                           onChange={(e)=>handleFloatInputChange(reRequestModalData, idx, e)}/>
                                :
                                (get답변유형OfObj(reRequestModalData, idx) === 'number')?
                                 <Input disabled={obj.doneFlag} type={'number'} style={{backgroundColor:'#f9f9f9'}}  name={'reAnswer'} value={obj.reAnswer} placeholder={'입력해 주세요'}
                                            onChange={(e)=>handleObjInputChange(reRequestModalData, idx, e)}/>
                                : (get답변유형OfObj(reRequestModalData, idx).includes('elect'))? //Select노드, selectBox 포함.
                                    <Select disabled={obj.doneFlag}
                                        placeholder={'선택해 주세요'}  style={{width: 500}}
                                        value={obj.reAnswer}
                                        onChange = { (value) => handleObjValueChange(reRequestModalData, idx, value) }
                                        options={ //[{ value: 'jack', label: 'Jack' },]
                                            get답변유형SelectItems(reRequestModalData, idx).map((item) => ({value: item}))
                                        }
                                    />
                                : (get답변유형OfObj(reRequestModalData, idx) === '[file]')?
                                    // <PublicFileUploader imageUrl={obj.reAnswer} readOnly={obj.doneFlag}
                                    //                     onChange={(returnUrl) => handleObjValueChange(reRequestModalData, idx, returnUrl)}
                                    //                     buttonText={'파일 선택'} name={'증거첨부'}/>
                                        <EvidenceUploader cmId={creditMaster.cmId}
                                                          fileList={obj.reAnswer}
                                                          evidenceFileList={evidenceFolderFileList}
                                                          onChange={(returnFileList) => handleObjValueChange(reRequestModalData, idx, returnFileList)}
                                                          //onChange={(returnFileList) => setCmFieldKv(prev => ({...prev, [obj.tableCmField + obj.notationPv]:returnFileList}) ) }
                                        />
                                : (get답변유형OfObj(reRequestModalData, idx) === 'date')?
                                    //antd OLD <DatePicker locale={locale}  disabled={obj.doneFlag} //name={colObj.cmField}
                                    //             value={obj.reAnswer? dayjs(obj.reAnswer):''}
                                    //             onChange={(date, dateString) => handleObjValueChange(reRequestModalData, idx, dateString)} />
                                   <DatePicker  locale={'ko'}  className={'simple-datepicker'} disabled={obj.doneFlag} //name={colObj.cmField}
                                                selected={obj.reAnswer? new Date(obj.reAnswer):''}
                                                dateFormat="yyyy-MM-dd" placeholderText={'연도는 숫자입력'}
                                                onChange={(dates) => handleReactDatePickerChange(reRequestModalData, idx,  dates?.toString())} />

                                : (get답변유형OfObj(reRequestModalData, idx) === 'dateMask')?
                                    <DateMaskPicker disabled={obj.doneFlag}
                                                    selected = {obj.reAnswer? obj.reAnswer: ''}
                                                    onChange={(dateMask) => handleObjValueChange(reRequestModalData, idx,  dateMask)}
                                    />
                                : (get답변유형OfObj(reRequestModalData, idx) === '채권들 선택') ? <>

                                                        { getCmNotationFieldVArr(creditMaster.원장, reRequestModalData.채권명Field).map ((채권명, 채권idx) =>
                                                              <Flex>
                                                                  <div> {채권명} </div>
                                                                  <Checkbox
                                                                    checked={(obj.reAnswer != undefined) ? String(obj.reAnswer).split(',').includes(String(채권idx)) : false}
                                                                    style={{marginLeft: 5}}
                                                                    onChange={(e) => handle채권들선택Change(reRequestModalData, idx, e.target.checked, 채권idx)}/>
                                                              </Flex>
                                                          )}

                                                        </>


                                                        : <Input disabled={obj.doneFlag} type={'text'}
                                                                 style={{backgroundColor: '#f9f9f9'}} name={'reAnswer'}
                                                                 value={obj.reAnswer} placeholder={'입력해 주세요'}
                                                                 onChange={(e) => handleObjInputChange(reRequestModalData, idx, e)}/>
                            }

                                <Title2_5> 기타 메모 및 요청사항</Title2_5>
                                <Input.TextArea style={{backgroundColor: '#f9f9f9'}} placeholder={'필요시 입력해 주세요'}
                                                rows={2} name={'userMemo'} value={obj.userMemo}
                                                onChange={(e) => handleObjInputChange(reRequestModalData, idx, e)}/>
                                {/*<div> {obj.userMemo}</div>*/}


                                <br/>
                                {/*</Space></FlexColumn>*/}
                            </>
                        })}


                    <GrayLine> </GrayLine>

                    {/*<div> {creditMaster.reRequestMap.toString()}</div>*/}

                    <br/>
                    <EmptyBox>
                        <Button style={{backgroundColor: '#ffd118', color: 'black'}} type={'primary'}
                                onClick={inputDoneOneQuestionModal}> 입력 </Button>
                    </EmptyBox>

                </FlexColumn></FlexCenter>
            </Modal>

        </div>
    )
}

export default StoryReRequest;