import { WhiteTopBar } from "../common/Common";
import {Card} from "antd";
import React from "react";

export function CompanyPage(props) {
    return (
        <div className="px-2">
            <WhiteTopBar title={(window.location?.host.startsWith('2ai'))?'회사 소개':'변호사 및 법률사무소 소개'}/>
            <br/>
            <CompanyContent/>
        </div>
    )
}

function CompanyContent(props) {

    if (window.location?.host.startsWith('2ai')) {
        return (
            <div className="text-left px-2" style={{fontSize:18, lineHeight:1.7}}>
                {/*<br />*/}

                {/*<b>회사 소개 </b> */}
                <Card title={<div style={{marginTop:8, fontSize:18}}>회사 소개</div>} hoverable="true" style={{fontSize:18,width:"100%"}}>
                    {/*<img style={{width:'100%'}} src={'https://v1-prod-s3-userprofile.s3.ap-northeast-2.amazonaws.com/images/24031710497901710aQwu5ONrMxSAfgEXOkFHF.jpg'}/><br/>*/}
                    {/*<br/>*/}
                    {/*디앤알티 주식회사는<br/><br/>*/}
                    우리 시대의 고통받는 선의의 채권생태계의 모든 참여자에게 희망을 주고자 <b>기술</b>(Artificial Intelligence와 Algorithm Intelligence기술을 탑재)과 <b>ESG 철학</b>을 법과 채권관리기술에 적용하였습니다. (50억원상당의 소프트웨어 적용)<br/>
                    그동안 우리 사회는 채권회수, 법률서비스의 벽이 너무 높았습니다. 상담하기도 어려웠고, 찾아가기도 어려웠으며 내가 받을 채권액에 비하여 수수료는 너무 커서 고통을 당하면서도 회수하지 못했습니다. 또 반대로 채권자와 채무자사이의 정보비대칭성으로 무분별한 분쟁이 많았습니다.
                    &nbsp;<b>채권생태계의 사각지대</b>에 있는 모든 분께 재산과 신용을 찾아드리고자 합니다. 그리고 더 나아가 <b>건강한 신용사회</b>를 만들어 가겠습니다.

                    {/*<br/>*/}
                    {/*<br/>*/}
                    {/*<div style={{float:'right'}}>· 대표자 : 이현우 </div>*/}
                    <br/>

                </Card>
                <br/>
                <br/>

                <div style={{marginBottom:8}}> <b>&lt;채권관리 파트너사&gt;</b> </div>

                <Card title={<div style={{fontSize:18}}>채권도시 법률사무소</div>} hoverable="true" style={{fontSize:18,width:"100%"}}>
                    <img style={{width:'100%'}} src={'https://v1-prod-s3-userprofile.s3.ap-northeast-2.amazonaws.com/images/24021708514659476lFzwjPqWXP345GZHD'}/><br/>
                    {/*<b> 채권도시 법률사무소 </b> */}
                    <br/>
                    <img style={{width:'100%'}} src={'/assets/logoC.png'}/>

                    <br/>

                </Card>
                <br/>

                <Card title={<div style={{fontSize:18}}>새한신용정보 (주)</div>} hoverable="true" style={{fontSize:18,width:"100%"}}>
                    <img style={{width:'100%'}} src={'https://v1-prod-s3-userprofile.s3.ap-northeast-2.amazonaws.com/images/24011706528205111WPwQvjn4dB2ahSyWlj.jpg'}/><br/>
                    {/*<b> 새한신용정보 </b> */}
                    <br/>
                    새한신용정보주식회사는 1968년 창업하여 50여년간 꾸준히 신용정보업계를 주도하며 성장하여 왔습니다.
                    국내 신용정보회사로서는 전국에 최대의 점포망을 구축하여 최고의 실력을 갖춘 신용관리사가 추심하므로 괄목할 만한 성과를 거두어 고객이 만족하는 신용정보사로 자리잡고 있습니다.

                    <br/>

                </Card>

                <br/>

            </div>


        )
    }



    return (
        <div className="text-left px-2" style={{fontSize:18, lineHeight:1.7}}>
            <strong>{(window.location?.host.startsWith('2ai'))?'채권 2AI':'채권도시 법률사무소'}</strong>는
            <br />
            <br />

            우리 시대의 고통받는 선의의 채권자에게 희망을 주고자 <b>기술</b>과 <b>ESG 철학</b>을 법과 채권회수기술에 적용하였습니다. (50억원상당의 소프트웨어 적용)<br/>
            그동안 우리 사회는 채권회수, 법률서비스의 벽이 너무 높았습니다. 상담하기도 어려웠고,
            찾아가기도 어려웠으며 내가 받을 채권액에 비하여 수수료는 너무 커서 고통을 당하면서도 회수하지 못했습니다.
            저희는 <b>적은 금액 단돈 몇만원부터 시작해서 높은 금액</b>까지 어떠한 금액이라도 정당한 선의의 채권자의 채권은 지켜줘야 한다고 믿고 있습니다.
            <br />
            저희 {(window.location?.host.startsWith('2ai'))?'회사':'법률사무소'}는 현재(2024년 1월) 기준 대한민국에서 제일 저렴한 소송비용으로 소송을 할수 있는 곳입니다.(공익적 목적의 특별 기간 한정, 특정 사건수 한정)<br/>
            <b>채권생태계의 사각지대</b>에 있는 모든 분께 재산과 신용을 찾아드리고자 합니다. 그리고 더 나아가 <b>건강한 신용사회</b>를 만들어 가겠습니다.

            <br/>
            <br/>


            <Card title={<div style={{fontSize:18}}>조근호 대표 변호사</div>} hoverable="true" style={{fontSize:18, width:"100%"}}>
                <img style={{width:'100%'}} src={'https://v1-prod-s3-userprofile.s3.ap-northeast-2.amazonaws.com/images/24031710497884634HM76oHoyOw93bpPSpn1C.jpg'}/><br/>
                {/*조근호 변호사 */}
                <br/>
                <b> “오랫동안 법조인으로 지내면서, 왜 법조계는 다른 산업계만큼 발전되지 못했나 많은 고민을 했습니다. 우리는 대한민국에 없던 리걸, 추심 테크놀로지를 도입하여 다른 곳에는 없는 경험과 역량을 가지고 채권자 여러분의 비용과 시간의 문제를 해결합니다.“
                </b>
                <br/>
                <br/>
                <b>경력</b><br/>
                · <a href={'https://www.edaily.co.kr/news/read?newsId=01344806615831176&mediaCodeNo=257'}> 관련 기사 링크 </a> <br/>
                · 행복마루 법률사무소 대표변호사<br/>
                · 행복마루 컨설팅 대표이사<br/>
                · 신세계그룹 사외이사<br/>
                · 현대카드 감사<br/>
                · 하나투어 사외이사<br/>
                · 디지털포렌식 산업포럼 회장<br/>
                · 황조근정훈장<br/>
                · 제36대 법무연수원 원장<br/>
                · 부산고등검찰청 검사장<br/>
                · 사법연수원 부원장<br/>
                · 민정수석실<br/>
                <br/>
                <b>학력</b><br/>
                · 서울대학교 법학과 졸업<br/>
            </Card>
            <br/>

            <Card title={<div style={{fontSize:18}}>황인규 대표 변호사</div>} hoverable="true" style={{fontSize:18, width:"100%"}}>
                <img style={{width:'100%'}} src={'https://v1-prod-s3-userprofile.s3.ap-northeast-2.amazonaws.com/images/24031710497847784BAbgsHKAlXFZ3S.jpg'}/><br/>
                <br/>
                {/*황인규 변호사*/}
                <b>“오랫동안 검사로 재직하면서, 그리고 한 기업의 대표로서 사회에 봉사하려고 힘써왔습니다. 이제 저는 그간의 경험을 활용하여 악성채무에 시달리는 채권자들의 사각지대에 테크와 ESG철학을 도입해 건전하고 생산적인 채권생태계를 조성하고자 합니다.”
                </b>

                <br/>
                <br/>

                <b>경력</b><br/>
                · 인천지방검찰청 부천지청장 <br/>
                · CNCITY에너지 대표이사 회장 <br/>
                · 4차 산업혁명융합법학회 부회장 <br/>
                · 한국형사소송법학회 고문 <br/>
                <br/>
                <b>학력</b><br/>
                · 서울대학교 법학과 졸업<br/>
                · Stanford Law School(Visiting Scholar)<br/>
            </Card>
            <br/>


            <Card title={<div style={{fontSize:18}}>김주현 대표 변호사</div>} hoverable="true" style={{fontSize:18,width:"100%"}}>
                <img style={{width:'100%'}} src={'https://v1-prod-s3-userprofile.s3.ap-northeast-2.amazonaws.com/images/24031710497901710aQwu5ONrMxSAfgEXOkFHF.jpg'}/><br/>
                {/*<b>김주현 변호사 </b> */}
                <br/>
                <b>"지난 10년 동안 채권자와 채무자 분들의 분쟁을 해결하기 위해 노력해왔습니다. 현재의 채권추심 생태계는 더 이상 단순하고 원시적인 회수방식으로는 운영될 수 없는 상황에 이르렀습니다. ‘채권도시 2AI’ 플랫폼을 통해 각 채권자와 채무자의 상황을 세밀하게 분석하고 대응하여, 채권 추심 생태계 내에서 채권 회수와 신뢰관계 유지라는 두 마리 토끼를 모두 잡을 수 있도록 하겠습니다.”
                </b>
                <br/>
                <br/>
                ·연세대학교 공과대학 졸업<br/>
                ·연세대학교 법학과 졸업<br/>
                ·연세대학교 법학과 석사, 박사(수료)<br/>
                ·연세대학교 체육회 부회장<br/>
                ·서울특별시 공익변호사<br/>
                ·대한변호사협회 대의원<br/>
                ·(사)대한민국예비역부사관총연합회 군 인권 함께하기 운동본부 법률지원위원<br/>

            </Card>
            <br/>
            <br/>

            <b> &lt;채권추심 파트너사&gt; </b><br/>

            <img style={{width:'100%'}} src={'https://v1-prod-s3-userprofile.s3.ap-northeast-2.amazonaws.com/images/24011706528205111WPwQvjn4dB2ahSyWlj.jpg'}/><br/>

            <b>새한신용정보 (주)</b><br/>
            새한신용정보주식회사는 1968년 창업하여 50여년간 꾸준히 신용정보업계를 주도하며 성장하여 왔습니다. 국내 신용정보회사로서는 전국에 최대의 점포망을 구축하여 최고의 실력을 갖춘 신용관리사가 추심하므로 괄목할 만한 성과를 거두어 고객이 만족하는 신용정보사로 자리잡고 있습니다.
            <br/>
            <br/>

        </div>
    );
}